
import { Vue, Component } from 'vue-property-decorator'
import { Detail } from '@/types/parkStrategy'

@Component
export default class HealthLife extends Vue {
  private searchInfo = {
    projectName: '',
    strategyTheme: ''
  }

  private page = 1
  private size = 10
  private total = 0
  private loading = false
  private tableData: Detail[] = []

  created () {
    this.getData()
  }

  // 查询
  searchData () {
    this.page = 1
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<Detail> }>(this.$apis.parkStrategy.selectParkStrategyByPage, {
      page: this.page,
      size: this.size,
      ...this.searchInfo
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  // 新增
  onAdd () {
    this.$router.push({ path: '/parkStrategy/add' })
  }

  onDetail (id: string) {
    this.$router.push({
      name: 'parkStrategyDetail',
      params: { id }
    })
  }

  onDelete (strategyId: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.parkStrategy.deleteParkStrategy, { strategyId }).then(() => {
        this.$message.success('删除成功')
        this.searchData()
      })
    }).catch(() => {
      console.log('已取消')
    })
  }
}
